import React from 'react'
import { AppBar, Toolbar, IconButton} from '@mui/material'
//import styled from 'styled-components'
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

import Icon from './Icon'

const StyledAppBar = styled(AppBar)` 
 background-color: #654ca3 
`

class Navigation extends React.Component {
 render() {
  return (
   <React.Fragment>
   <StyledAppBar>
    <Toolbar variant="dense">
     <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
     <Icon />
     </IconButton>
     <Typography variant="h6" color="inherit" component="div">
      Inventario
     </Typography>
    </Toolbar>
   </StyledAppBar>
   <Toolbar/>
   </React.Fragment>
  );
 }
}

export default Navigation;