import React from 'react'
import Navigation from './components/Navigation'

class Home extends React.Component {
 render() {
  return (
   <React.Fragment>
    <Navigation />
    <h1> Hola! Pronto estaremos operando nuestro programa de lealtad por aqui! Mientras, siguenos en Facebook o Instagram! </h1>
   </React.Fragment>
  );
 }
}

export default Home;