import React from 'react'
import { ReactComponent as ReactIcon } from './icon.svg'
import { styled } from '@mui/system'

const StyledIcon = styled(ReactIcon)` 
  width: 40px; 
  height: 40px;
`
 
export default function Icon() {
 return(
  <StyledIcon/>
 )
}