import React from 'react'
import Navigation from './components/Navigation'

class NotFoundPage extends React.Component {
 render() {
  return (
   <React.Fragment>
    <Navigation />
    <h1> Oh dear, we couldnt find the page you are looking for </h1>
   </React.Fragment>
  );
 }
}

export default NotFoundPage;