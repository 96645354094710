import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Icon from './components/Icon'

class Test extends React.Component {
 render() {
  return (
   <React.Fragment>
   <AppBar sx={{ bgcolor: "#654ca3" }}>
    <Toolbar variant="dense">
     <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
     <Icon />
     </IconButton>
     <Typography variant="h6" color="inherit" component="div">
      Photos
     </Typography>
    </Toolbar>
   </AppBar>
   <Toolbar/>
   </React.Fragment>
  );
 }
}

export default Test;