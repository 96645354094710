import Home from './Home';
import Inventory from './Inventory';
import Test from './Test';
import NotFoundPage from './NotFoundPage';
//import ContactPage from './ContactPage';
//import { StylesProvider } from "@mui/styles";
//import GlobalFonts from './fonts/fonts';
import { BrowserRouter, Route, Routes } from "react-router-dom";


function App() {
 return (
  <BrowserRouter>
   <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/inventory" element={<Inventory/>} />
    <Route path="/test" element={<Test/>} />
    <Route element={<NotFoundPage/>} />
   </Routes>
  </BrowserRouter>
 );
}

export default App;
