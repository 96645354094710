import React from 'react'
import Navigation from './components/Navigation'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '80%',
  maxHeight: '80%',
});



class Inventory extends React.Component {
 render() {
  return (
   <React.Fragment>
    <Navigation />
    <Box sx={{ flexGrow: 1 }}>
      <Paper>
       <Grid container spacing={2}>
        <Grid item xs={2}>
         <Img alt="complex" src="https://www.herdereditorial.com/media/cache/0d/12/0d12257d3b20f44bba40968e46ccc1ec.jpg"/>
        </Grid>
        <Grid container spacing={2} xs={10}>
         <Grid item xs={12}>
          <Typography sx={{ p: 2 }} variant="h3" component="div">
           The Title of the Book
          </Typography>
          <Typography variant="h6 " component="div">
           1234567890987654321
          </Typography>
         </Grid>
         <Grid item xs={4}>
          <TextField id="outlined-basic" label="Key" variant="standard" defaultValue="Value" size="small" fullWidth/>
         </Grid>
        </Grid>  
       </Grid>
      </Paper>
    </Box>
   </React.Fragment>
  );
 }
}

export default Inventory;